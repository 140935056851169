import { get, unset } from "lodash"
import { useDispatch } from "react-redux"

import { ISetFlyoutContentURIAction, setFlyoutContentURIAction } from "@redux/actions/application"
import { useDynamicTranslation } from "@services/i18n"
import { JsonEntriesType, JSONHelper } from "@services/JSONHelper"

import { FAQKeys } from "./FAQHelper"

/**
 * hook to be used to change the current FAQ key
 * may only be used within components (b/c of hooks may only be used there)
 *
 * @returns a function to set the current FAQ key
 */
export const useSetFAQKey = (): ((faqKey: string) => ISetFlyoutContentURIAction) => {
  const dispatch = useDispatch()

  // this function is returned: to dispatch the action to change the FAQ key
  const setFaqKey = (faqKey: string) => dispatch(setFlyoutContentURIAction(faqKey))

  return setFaqKey
}


/**
 * A custom hook to load the current faq content.
 *
 * @returns javascriptObject of faq.json-file
 */
export const useFAQObject = (): object => {
  const t = useDynamicTranslation()
  // get all faq entries as an object: the . is the signal to return all elements of the namespace
  const faqEntries: JsonEntriesType = t('faq', '.', {}, { returnObjects: true })

  // #region remove all entries that have an attribute "hidden" with value "true"
  // NOTE: removing an element means, it is not available/visible in the shown FAQ.
  // In this case pointing on a single entry e.g. by FAQPopup may fail.
  const allKeys = JSONHelper.getAllAssembledKeysFromJsonObject(faqEntries)
  const hiddenEntries = allKeys
    // all keys ending with "hidden"
    .filter(key => key.endsWith(FAQKeys.Hidden))
    // filter all keys where "hidden" is "true"
    .filter(key => get(faqEntries, key) === "true")
    // cut off the "hidden" part of the key to get the key to hide
    .map(key => key.substring(0, key.lastIndexOf(".")))

  // remove/unset all elements that are recognized as "hidden"
  hiddenEntries.forEach(key => unset(faqEntries, key))

  // #endregion remove all entries that have an attribute "hidden" with value "true"


  // #region remove empty topics and areas

  /** function to check, of an entry has at least one sub entry that is not a string */
  const hasAtLeastOneSubEntry = (entry: JsonEntriesType): boolean =>
    !!Object.keys(entry).find(key => typeof get(entry, key) !== "string")


  const leftKeys = JSONHelper.getAllAssembledKeysFromJsonObject(faqEntries)
  // all topic keys split to 2 parts, because they have one .
  const topicKeys = leftKeys.filter(key => key.split('.').length === 2)
  // all area keys split to 1 part, because they do not have a .
  const areaKeys = leftKeys.filter(key => key.split('.').length === 1)

  // cleanup topics
  topicKeys.forEach(key => {
    const entry = get(faqEntries, key) as JsonEntriesType
    if (typeof entry !== "string" && !hasAtLeastOneSubEntry(entry)) {
      unset(faqEntries, key)
    }
  })

  // cleanup areas
  areaKeys.forEach(key => {
    const entry = get(faqEntries, key) as JsonEntriesType
    if (typeof entry !== "string" && !hasAtLeastOneSubEntry(entry)) {
      unset(faqEntries, key)
    }
  })

  // #endregion remove empty topics and areas

  return faqEntries
}
