import get from "lodash/get"
import has from "lodash/has"
import useTranslation from "next-translate/useTranslation"
import React, { ReactNode, useEffect, useRef, useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Tooltip } from "reactstrap"

interface IProps {
  "aria-label"?: string
  autoWrap?: boolean
  button: any
  className?: any
  light?: boolean
  title?: string
  children: ReactNode
}

const DropdownComponent: React.FC<IProps> = (props: IProps) => {
  // per default all children are automatically wrapped in <DropdownItem>
  // this can be prevented by setting autoWrap to false, e.g. for cases when the Child is a React element
  // and thous will not be detected as empty, but still renders empty in some conditions, e.g. AuthElements
  // when the user has not the required role -> don't render empty Buttons, in this case include the
  // <DropdownItem> within the Authelements and all children of DropdownComponent must be wrapped manually
  const { autoWrap = true } = props

  const wrappedChildren = autoWrap
    ? React.Children.map(props.children, (child, i) => {
      // fix react error: items in a list must have a key
      const key = has(child, "key") ? `key-${get(child, "key") as string}` : `index-${i}`

      return <>{child &&
        <DropdownItem key={key}>
          {child}
        </DropdownItem>
      }</>
    })
    : props.children

  const { t } = useTranslation("common")
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)


  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  // Hint of the official documentation: https://reactjs.org/docs/hooks-reference.html#useref
  /**
   * Keep in mind that useRef doesn’t notify you when its content changes.
   * Mutating the .current property doesn’t cause a re-render.
   * If you want to run some code when React attaches or detaches a ref to a DOM node,
   * you may want to use a callback ref instead.
   */
  // e.g the tooltip should be display when the targets are initialized, that mean's if the target reference is initialized
  // if the tooltipTargetRef is still null, the tooltip will not be rendered
  // Be aware: the tooltipTargetRef can be intialized before the first render, but there is no guarante
  //
  // solution: adding a useState und a useEffect, which has the tooltipTargetRef in the dependency array
  // the callback idea is not suitable in this case with making sure to display the tooltip, cause with the callback, there is e.g. the possiblity to do e.g. manipulation on the target or focus,
  // but to display the tooltip, a rerender is needed, therefor a useState and a useEffect is the suitable solution
  const [targetRefInitialized, setTargetRefInitialized] = useState(false)
  // reference to to the tooltip target, initially null => needed to make sure, that the tooltip is rendered after the tooltip targets are mounted
  const tooltipTargetRef = useRef(null)

  useEffect(() => {
    if (tooltipTargetRef?.current) {
      setTargetRefInitialized(true)
    }
  }, [tooltipTargetRef])


  return <>
    <Dropdown
      className={props.className}
      onFocus={() => setTooltipOpen(true)}
      onBlur={() => setTooltipOpen(false)}
      isOpen={isOpen}
      toggle={toggle}
      title={props.title}
    >
      <DropdownToggle aria-label={props["aria-label"] || t("default.dropdown")}>
        <span ref={tooltipTargetRef}>
          {props.button}
        </span>
      </DropdownToggle>
      <DropdownMenu className={props.light ? "dropdown-menu-light" : ""} right>
        {wrappedChildren}
      </DropdownMenu>
    </Dropdown>
    {targetRefInitialized && props.title && <Tooltip
      target={tooltipTargetRef.current}
      toggle={toggleTooltip}
      isOpen={tooltipOpen} placement="left"
    >
      {props.title}
    </Tooltip>}
  </>
}

export default DropdownComponent
