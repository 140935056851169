import { isValid } from 'date-fns'
import { v4 as uuidv4 } from "uuid"

import {
  FeedbackInvitationState,
  FeedbackPostType,
  IDiscussion,
  IFeedbackInvitation,
  IFeedbackPost,
  IFeedbackQuestion,
  IProject,
  IRI,
  IUser,
} from "@api/schema"
import { IconName } from "@components/common/Icon"
import { idFromIModelOrIRI, isInt } from '@services/util'


// #region interfaces + enums
// used to save a single post/content with a type. Currently used in SinglePostForm
export interface ISinglePostData {
  content: string
  type: FeedbackPostType
}

// #endregion




/*
 * FeedbackHelper: Class with helper-functions for the feedback-context
 */
export class FeedbackHelper {
  // #region constants
  public static MAX_FEEDBACK_POST_CONTENT_LENGTH = 800

  // #endregion constants


  // #region pre filled data (functions)
  /* ************************************************************************** */
  /* Pre-filled data for initial objects based on existing schmemas             */
  /* using the prefix "Fresh" to have a standard in the code                    */
  /* ************************************************************************** */

  /**
   * @returns a pre-filled IFeedbackQuestion to be used as an standard fresh question.
   * Must be used as function, to execute uuidv4() for every new FBI.
   */
  static getFreshFBIQuestion(): IFeedbackQuestion {
    const newFBIQuestion = {
      id: uuidv4(),
      question: "",
      explanation: "",
      version: 1
    }
    return newFBIQuestion
  }

  /**
   * @returns a pre-filled IFeedbackQuestion to be used as an standard fresh question
   */
  static getFreshFeedbackInvitation(): IFeedbackInvitation {
    const newFBI: IFeedbackInvitation = {
      // "@id": uuidv1(),
      activeUntil: null,
      anonymousFeedback: false,
      createdAt: new Date().toString(),
      createdBy: null,
      questions: [],
      title: "",
    }

    return newFBI
  }



  /**
   * Creates a fresh NewIFeedbackPost with newContent based on the given FeedbackInvitation
   */
  static createNewFeedbackPostFromFBI(fbi: IFeedbackInvitation): IFeedbackPost {
    const contentArray: { [key: string]: string } = {}
    // create an Array with empty entry for every fbi-question
    fbi?.questions?.map((q) => contentArray[q.id] = "")

    return fbi && fbi['@id']
      ? {
        content: contentArray,
        type: FeedbackPostType.Hint,
        discussion: {
          contentId: "",
          feedbackInvitation: fbi["@id"],
          initialPost: null
        }
      }
      : undefined
  }

  /**
   * Creates a fresh NewIFeedbackPost with newContent based on the given ParentPost
   */
  static createNewFeedbackPostFromParentPost(parentPost: IFeedbackPost): IFeedbackPost {
    const contentArray: { [key: string]: string } = {}

    return parentPost && parentPost["@id"]
      ? {
        content: contentArray,
        type: null, // must be set by user
        parent: parentPost["@id"],
        discussion: parentPost.discussion
      }
      : undefined
  }

  /**
   * Creates a fresh NewIFeedbackPost with newContent based on given relatedObject and an optional contentId
   */
  static createNewFeedbackPostFromRelatedObject(relatedObject: IRI, contentId?: string): IFeedbackPost {
    const contentArray: { [key: string]: string } = {}

    return relatedObject
      ? {
        content: contentArray,
        type: null, // must be set by user
        discussion: {
          contentId,
          relatedObject,
          initialPost: null
        }
      }
      : undefined
  }

  // #endregion

  // #region helper functions

  /* ************************************************************************** */
  /* helper-functions                                                           */
  /* ************************************************************************** */


  // helper to give questions the current version
  // @todo: as long it is not possible to initialize MultiFieldArrays with hidden fields -> then version should be initialized with the current used version number
  static versionizeQuestions(questions: IFeedbackQuestion[]): IFeedbackQuestion[] {
    return questions.map((q) => {
      q.version = 1
      return q
    })
  }


  /**
   * Returns true, if a FeedbackPost as parent is in the parent-chain of a sub-FeedbackPost
   *
   * @param parentPostId ID of the post, that is up in the chain of the subPost
   * @param subPostId ID of the sub-post, that may be a sub-post in the chain of the parent-post
   * @param postCollection an array of FeedbackPosts to search for that connection
   * @returns true, if the subPost is in the chain under the parentPost
   */

  static isSubPost(parentPostId: number, subPostId: number, postCollection: IFeedbackPost[]): boolean {
    const subPost = postCollection.find(post => post.id && post.id === subPostId)
    const parentPost = postCollection.find(post => post.id && post.id === parentPostId)
    if (subPost && parentPost) {
      // find the post, that is the direct parent of the subPost
      let parent = postCollection.find(post => post["@id"] === subPost.parent)
      if (!parent) {
        // no parent found? there is no connection between the two posts
        return false
      } else {
        // parent found and parent-ID is the searched ID: both posts are connected!
        if (parent.id === parentPostId) {
          return true
        }
      }
      // search the chain upwards, until the initial post without parent is found
      while (parent) {
        parent = postCollection.find(post => post["@id"] === parent.parent)
        if (!parent) {
          // no parent found? there is no connection between the two posts
          return false
        } else {
          // parent found and parent-ID is the searched ID: both posts are connected!
          if (parent.id === parentPostId) {
            return true
          }
        }
      }
      // if no connection is found, there is no connection
      return false
    }

    return false
  }

  /**
   * Matches the used FeedbackPostType to an existing IconName
   *
   * @param commentType the type of a FeedbackPost
   * @returns the name of an Icon, matching this type
   */
  static feedbackPostIcon(commentType: FeedbackPostType): IconName {
    switch (commentType) {
      case FeedbackPostType.Criticism:
        return "comment-critizise"
      case FeedbackPostType.Hint:
        return "comment-hint"
      case FeedbackPostType.Question:
        return "comment-ask"
      case FeedbackPostType.Support:
        return "comment-support"
      case FeedbackPostType.Troll:
        return "comment-troll"
    }
  }


  /**
   * Calculates the state of an FeedbackInvitation depending on the activeUntil-Date
   *
   * @param fbi the FeedbackInvitation to be calculated on
   * @returns a valid FeedbackInvitationState
   */
  static fbiState(fbi: IFeedbackInvitation): FeedbackInvitationState {
    return fbi
      ? (fbi.activeUntil) && isValid(new Date(fbi.activeUntil))
        ? new Date(fbi.activeUntil) > new Date()
          ? FeedbackInvitationState.Active
          : FeedbackInvitationState.Expired
        : FeedbackInvitationState.Preparing
      : undefined
  }

  /**
   * is the given FeedbackInvitation active?
   *
   * @param fbi the FeedbackInvitation to be tested
   * @returns true, if the FeedbackInvitation is active
   */
  static fbiActive(fbi: IFeedbackInvitation): boolean {
    return FeedbackHelper.fbiState(fbi) === FeedbackInvitationState.Active
  }

  /**
   * Creates a standardized ID for an FBICard to be used as anchor to let the user jump to the FBI-card
   *
   * @param fbi the FeedbackInvitation
   * @returns a standardized string
   */
  static fbiCardId(fbi: IFeedbackInvitation): string {
    return fbi && fbi.id ? "fbi-" + fbi.id.toString() : ""
  }

  /**
   * name of the content input field
   * NOTE: the . is important of input arrays using the same name!
   *
   * @param questionId id of the question
   * @returns the name of the input field of the content of a FBI question, pattern "content.fbiId"
   */
  static getFBIQuestionContentInputFieldName(questionId: string): string {
    return "content" + "." + questionId
  }

  // #endregion

  // #region usecaseKey creator functions
  /**
   * Helper to create a standardized unique string
   * to be used as usecaseKey for API-calls
   * for Discussions of a User regarding a FeedbackInvitation.
   *
   * @param user the user who created discussions
   * @param fbi the fbi he answered to
   * @returns a unique string to be used as usecaseKey
   */
  static usecaseKeyForUserDiscussionsOnFBI = (user: IUser | IRI, fbi: IFeedbackInvitation | IRI): string =>
    "discussions_for_user_" + idFromIModelOrIRI(user)?.toString() + "_for_fbi_" + idFromIModelOrIRI(fbi)?.toString()

  /**
   * Helper to create a standardized unique string
   * to be used as usecaseKey for API-calls
   * for Discussions of a User.
   *
   * @param user the user who created discussions or a users ID
   * @returns a unique string to be used as usecaseKey
   */
  static usecaseKeyForUsersDiscussions = (user: IUser | string | number): string =>
    "discussions_for_user_" +
    // check, if parameter is a number
    (isInt(user as string)
      ? user.toString()
      : idFromIModelOrIRI(user as IUser | IRI)?.toString())

  /**
   * Helper to create a standardized unique string
   * to be used as usecaseKey for API-calls
   * for Discussions of a FeedbackInvitation.
   *
   * @param fbi the feedbackinvitation for which discussions are created
   * @returns a unique string to be used as usecaseKey
   */
  static usecaseKeyForFBIDiscussions = (fbi: IFeedbackInvitation | IRI): string =>
    "discussions_for_fbi_" + idFromIModelOrIRI(fbi)?.toString()

  /**
   * Helper to create a standardized unique string
   * to be used as usecaseKey for API-calls
   * for Discussions of a Project.
   *
   * @param project the Project for which discussions are created
   * @returns a unique string to be used as usecaseKey
   */
  static usecaseKeyForProjectDiscussions = (project: IProject | IRI): string =>
    "discussions_for_project_" + idFromIModelOrIRI(project)?.toString()

  /**
   * Helper to create a standardized unique string
   * to be used as usecaseKey for API-calls
   * for FeedbackPosts of a Discussion.
   *
   * @param discussion the Discussions for which FeedbackPosts are created
   * @returns a unique string to be used as usecaseKey
   */
  static usecaseKeyForDiscussionsFeedbackPosts = (discussion: IDiscussion | IRI): string =>
    "posts_for_discussion_" + idFromIModelOrIRI(discussion)?.toString()

  // #endregion
}