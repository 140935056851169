import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import { Button, Tooltip } from "reactstrap"

import Icon, { IconName } from "@components/common/Icon"

interface IProps {
  arialabel?: string
  color?: string
  className?: string
  href?: string | { pathname: string; query: any }
  onclick?: (...args: any) => void
  title: string
  icon?: IconName
  size?: number
  buttontext?: string
  tooltipText?: string
  disabled?: boolean
  type?: "submit" | "reset" | "button"
}

/**
 * This component provides a standard-usage of Icons to be displayed, depending on:
 * providing a link -> it uses the Link-component
 * providing a function to be opened on click -> it uses the Button component
 * It always brings a tooltip
 * No extra class needed when displayed as default ActionIcon
 * No size needed, size controlled by rem (text size)
 * The ActionIcon is wrapped around a span element with the id, in order to be identified by the optional tooltip.
 *
 */
const ActionIcon: React.FC<IProps> = (props: IProps) => {
  const {
    arialabel,
    color,
    className,
    href,
    onclick,
    title,
    icon,
    size,
    buttontext,
    tooltipText = title,
    disabled,
    type = "button"
  } = props

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }

  const classNameButton = (
    className
      ? className
      : "actionicon"
  ) + (
      disabled
        ? " disabled"
        : ""
    )

  // Hint of the official documentation: https://reactjs.org/docs/hooks-reference.html#useref
  /**
   * Keep in mind that useRef doesn’t notify you when its content changes.
   * Mutating the .current property doesn’t cause a re-render.
   * If you want to run some code when React attaches or detaches a ref to a DOM node,
   * you may want to use a callback ref instead.
   */
  // e.g the tooltip should be display when the targets are initialized, that mean's if the target reference is initialized
  // if the tooltipTargetRef is still null, the tooltip will not be rendered
  // Be aware: the tooltipTargetRef can be intialized before the first render, but there is no guarante
  //
  // solution: adding a useState und a useEffect, which has the tooltipTargetRef in the dependency array
  // the callback idea is not suitable in this case with making sure to display the tooltip, cause with the callback, there is e.g. the possiblity to do e.g. manipulation on the target or focus,
  // but to display the tooltip, a rerender is needed, therefor a useState and a useEffect is the suitable solution
  const [targetRefInitialized, setTargetRefInitialized] = useState(false)
  // reference to to the tooltip target, initially null => needed to make sure, that the tooltip is rendered after the tooltip targets are mounted
  const tooltipTargetRef = useRef(null)

  useEffect(() => {
    if (tooltipTargetRef?.current) {
      setTargetRefInitialized(true)
    }
  }, [tooltipTargetRef])

  return <><span>
    {href
      ? disabled
        ? <span><Icon iconRef={tooltipTargetRef} name={icon} size={size} title={title} /></span>
        : <Link href={href}>
          <a
            ref={tooltipTargetRef}
            className={className ? className : "actionicon"}
            title={title}
            aria-label={arialabel}
            onFocus={() => setTooltipOpen(true)}
            onBlur={() => setTooltipOpen(false)}
          >
            <Icon name={icon} size={size} title={title} />
          </a>
        </Link>
      : onclick
        ? <><Button
          aria-label={arialabel}
          color={color}
          className={classNameButton}
          onClick={onclick}
          title={title}
          type={type}
          disabled={disabled}
          onFocus={() => setTooltipOpen(true)}
          onBlur={() => setTooltipOpen(false)}
        >
          {icon ? <Icon iconRef={tooltipTargetRef} name={icon} size={size ? size : 24} title={title} /> : <span ref={tooltipTargetRef}>{buttontext}</span>}
        </Button>
        </>
        : <Button
          aria-label={arialabel}
          color={color}
          className={classNameButton}
          title={title}
          type={type}
          disabled={disabled}
          onFocus={() => setTooltipOpen(true)}
          onBlur={() => setTooltipOpen(false)}
        >
          {icon ? <Icon iconRef={tooltipTargetRef} name={icon} size={size ? size : 24} title={title} /> : <span ref={tooltipTargetRef}>{buttontext}</span>}
        </Button>
    }
  </span>
    {/* if a tooltiptext is not empty and if the tooltip target is only mounted, render the the tooltip */}
    {tooltipText !== "" && targetRefInitialized && (
      <Tooltip
        isOpen={tooltipOpen}
        target={tooltipTargetRef.current}
        toggle={toggleTooltip}
      >
        {tooltipText}
      </Tooltip>
    )}
  </>
}

export default ActionIcon
