import Flyout from '@components/flyout/Flyout'
import { MODULE_FAQ_AVAILABLE } from "config"

import FAQMainComponent from './FAQMainComponent'

/**
 * Wraps the FAQ component into a Flyout
 */
const FAQFlyout = (): JSX.Element => {
  return MODULE_FAQ_AVAILABLE && <Flyout>
    <FAQMainComponent />
  </Flyout>
}

export default FAQFlyout