/** constants, which are used in several components and/or pages */


/* ************************************************************************** */
/* constants                                                                  */
/* ************************************************************************** */

export const hashIdContactPageBugReport = "#bug-report"


/* ************************************************************************** */
/* Pre-filled data for initial objects based on existing schmemas             */
/* using the prefix "Fresh" to have a standard in the code                    */
/* ************************************************************************** */



/* ************************************************************************** */
/* helper-functions                                                           */
/* ************************************************************************** */

/**
 * Helper to create a standardized unique string
 * to be used as usecaseKey for API-calls
 * for projects with a given category.
 *
 * @param categoryId id of the category
 * @returns a unique string to be used as usecaseKey
 */
export const usecaseKeyForProjectsByCategory = (categoryId: number): string =>
  `projects_for_category_${categoryId.toString()}`
