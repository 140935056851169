/*
 * Collection of helper-functions, enums and interfaces for the FAQ-system.
 *
 * Used by FAQMainComponent and all its sub-components.
 *
 * a faqKey is expected to match the pattern
 * "area.topic.entry"
 * but may also be just a stub of it, e.g.
 * "area.topic" or just "area"
 */


/**
 * Special keys used within the FAQ context
 */
export enum FAQKeys {
  // marks a picture of an faq area
  Picture = "picture",
  // marks a title of an entry
  Title = "title",
  // marks elements as "hidden" -> used for customized FAQs to hide single entries or a whole tree of entries
  Hidden = "hidden",
  // marks a question in a faq entry
  Question = "question",
  // marks an answer in a faq entry
  Answer = "answer"
}

/**
 * enum of layers, used by the FAQ data structure
 */
export enum FAQLayer {
  AreaLayer = "areaLayer",
  TopicLayer = "topicLayer",
  EntryLayer = "entryLayer",
}

/**
 * type of a whole FAQ object, constisting of FAQ areas
 */
export type FAQType =
  {
    [key: string]: FAQAreaType
  }

/**
 * intersection - type => an area consists of a title, optionally of a picture and of one or many topics
 *
 * NOTE: this type definition is a hack: the union allows extending the core type by arbitrary entries,
 * but object assigments will fail (and must be casted) @see: https://github.com/microsoft/TypeScript/issues/17867
 */
export type FAQAreaType = {
  title: string
  picture?: string
  invisible?: string
} & { [key: string]: FAQTopicType }

/**
 * intersection - type => a topic consists of a title and of one or many entries
 *
 * NOTE: this type definition is a hack: the union allows extending the core type by arbitrary entries,
 * but object assigments will fail (and must be casted) @see: https://github.com/microsoft/TypeScript/issues/17867
 */
export type FAQTopicType = {
  title: string
  invisible?: string
} & { [key: string]: FAQEntryType }

/**
 * type of an question->answer FAQ entry
 */
export type FAQEntryType = {
  answer: string
  question: string
  invisible?: string
}

/**
 * FAQ entry with key
 */
export type IndexedFAQEntryType = {
  [key: string]: FAQEntryType
}

/**
 * represents a single FAQ-Entry, extended by the faqKey it is based on and an index,
 * when its used in an array of other entries
 */
export interface ISelectedEntry extends FAQEntryType {
  key: string
  index: number
}

export class FAQHelper {

  /**
   * @param key a string to check, if it is part of reserved FAQKeys
   * @returns true, if the given string is part of the reserved FAQKeys, otherwise: false
   */
  public static isReservedFAQKey(key: string): boolean {
    return Object.values(FAQKeys).includes(key as FAQKeys)
  }

  /**
   * Returns the ordered part of the given faq-key
   *
   * @param faqKey
   * @param part number of the parts: 1, 2, 3
   * @returns name of area or undefined
   */
  public static getKeyPart(faqKey: string, part: number): (string | undefined) {
    if (!faqKey || !part) {
      return undefined
    }

    const key = faqKey?.split(".")
    return key?.length >= part ? key[part - 1] : undefined
  }


  /**
   * Calculates and returns the layer-type, which should be shown to the user
   * depending on the structure of the given FAQKey.
   * Depending on the number of key-parts (parted by '.') it returns the layer-type.
   *
   * if the faq key consists of 2 parts: the topic layer should be shown: all entries of the topic should be shown
   * if the faq key consists of 3 parts: the entry layer should be shown: a single entry
   * the area layer is the fallback: if no, undefined or an mistaken faqKey is inputted it should be shown
   *
   * @param faqKey the current or any faqKey
   * @returns the layer to be shown to the user based on the faqKey structure
   */
  public static layerToBeShown(faqKey: string): FAQLayer {
    // return AreaLayer always, if faqKey is not set or empty string
    if (!faqKey) {
      return FAQLayer.AreaLayer
    }

    // split the key and check the number of resulting elements
    const layers: string[] = faqKey.split(".")
    switch (layers.length) {
      case 1:
      case 2:
        return FAQLayer.TopicLayer
      case 3:
        return FAQLayer.EntryLayer
      default:
        return FAQLayer.AreaLayer
    }
  }

  /**
   * Extract the upper layer top be shown of a (3-level-)faqKey.
   *
   * @param faqKey to interprete for "upper layer"
   * @returns area.topic if area.topic.entry is given, otherwise always ""
   */
  public static getUpperLayerToBeShown(faqKey: string): string {
    if (!faqKey) {
      return ""
    }

    const keyArray = faqKey.split(".")

    if (keyArray.length === 3) {
      return keyArray[0] + "." + keyArray[1]
    }

    return ""
  }


  /**
   * function to filter entries for a search string
   */
  public static searchInEntries(entries: IndexedFAQEntryType, searchString: string): IndexedFAQEntryType | undefined {
    if (entries && searchString) {
      const result: IndexedFAQEntryType = {}
      // Javascript - Arrays, which have an non-numeric index, will have not update the array attribute length,
      // therefor we need to count the length manually
      let resultLength = 0
      const searchStringLowerCase = searchString.toLocaleLowerCase()
      // go thru all entries, save every entry to the result-array and count it
      Object.keys(entries).map(key => {
        if (entries[key].question.toLocaleLowerCase().search(searchStringLowerCase) >= 0 // search in question
          || entries[key].answer.toLocaleLowerCase().search(searchStringLowerCase) >= 0) // search in answer
        {
          result[key] = entries[key]
          resultLength++
        }
      })

      // if results are found, return them, if not, return "undefined"
      if (resultLength > 0) {
        return result
      }
    }

    return undefined
  }

  /**
   * extract all entries from a given FAQ-File (faqnode)
   */
  public static getAllEntries(faqNode: FAQType): { [index: string]: FAQEntryType } {
    const allEntries: { [index: string]: FAQEntryType } = {}
    // first layer: areas
    Object.keys(faqNode).map((areaKey) => {
      const areaLayer = faqNode[areaKey]
      // Second Layer: topics
      // ignore special tags title&picture
      Object.keys(areaLayer).filter(value => value !== FAQKeys.Title && value !== FAQKeys.Picture).map((topicKey) => {
        const topicLayer = areaLayer[topicKey]
        // Third Layer: contains all entries
        Object.keys(topicLayer).filter(value => value !== FAQKeys.Title).map((entryKey) => {
          const entry: FAQEntryType = topicLayer[entryKey]
          // every entry is identified by its complete, unique key
          allEntries[areaKey + "." + topicKey + "." + entryKey] = {
            question: entry.question,
            answer: entry.answer,
          }
        })
      })
    })

    return allEntries
  }

  /**
   * Find all entries by a given Key with at least an area and a topic.
   *
   * @todo simplify by using lodash functions or Regex (@see: tests in faq.spec.tsx)?
   *
   * @param faqNode contains the data
   * @param faqKey contains the area as well as the topic
   * @returns all founded entries
   */
  public static getAllEntriesByFAQKey(faqNode: FAQType, faqKey: string): ISelectedEntry[] {
    // Checking, if faqKey is defined
    if (faqKey) {
      // get all parts of the FAQKey
      const keyArray = faqKey.split(".")
      const areaName = keyArray[0]
      const topicName = keyArray[1]
      // check if the FAQKey contains all 3 layers
      if (keyArray && keyArray.length === 3) {
        const areaLayer: FAQAreaType = faqNode[areaName]
        const topicLayer: FAQTopicType = areaLayer[topicName]
        // get all entries from the topic
        const foundEntryKeys = Object.keys(topicLayer).filter(value => value !== FAQKeys.Title)
        const foundEntries: ISelectedEntry[] = Array(foundEntryKeys.length)
        // loop through all entries and initiate them
        foundEntryKeys.map((key, index) => {
          const entry: FAQEntryType = (topicLayer)[key]
          // the identifier of each entry is an index, which makes it easier through navigate through them
          foundEntries[index] = {
            question: (entry).question,
            answer: (entry).answer,
            key,
            index
          }
        })

        return foundEntries
      }
    }
    // in case of no other return value, return an empty array
    return []
  }
}