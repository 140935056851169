import get from "lodash/get"

/**
 * represents an entry of a (translation) file/json
 */
export type JsonEntriesType = {
  [key: string]: string | Record<string, unknown>
}

export class JSONHelper {
  /**
   * provides an array of all keys of a translation file as array like
   * [
   * "page",
   * "page.about",
   * "page.about.title",
   * "page.contact",
   * "page.contact.title"
   * ...
   * ]
   */
  public static getAllAssembledKeysFromJsonObject(jsonObject: JsonEntriesType): string[] {
    // helper function to recursively extract sub keys
    // returns a given key if the value is a string, otherwise returns a list of sub-keys
    const getSubKeys = (key: string, subEntries: JsonEntriesType): string[] => {
      let keys: string[] = [key]
      const entry = get(subEntries, key)
      if (typeof entry === "object") {
        const subKeys = Object.keys(entry)
        subKeys.forEach(subkey => {
          keys = [].concat(keys, getSubKeys(key + "." + subkey, subEntries))
        })

      }

      return keys
    }

    let keys: string[] = []
    const firstLevelKeys = Object.keys(jsonObject)
    firstLevelKeys.forEach(key => {
      keys = [].concat(keys, getSubKeys(key, jsonObject))
    })

    return keys
  }


  /**
   * provides an array of all keys of a translation file as array like
   * [
   * "page",
   * "about",
   * "title",
   * "contact",
   * "title"
   * ...
   * ]
   *
   * to be used in test
   */
  public static getAllSingleKeysFromJsonObject(jsonObject: JsonEntriesType): string[] {
    // helper function to recursively extract sub keys
    const getSubKeys = (basisKey: string, subkey: string, subEntries: JsonEntriesType): string[] => {
      let keys: string[] = [subkey]

      const assembledKey = (basisKey !== "" ? basisKey + '.' : '') + subkey

      const entry = get(subEntries, assembledKey)

      // handle objects: get sub-keys
      if (typeof entry === "object") {
        const subKeys = Object.keys(entry)
        subKeys.forEach(subsubkey => {
          // add current keys and sub-entry-keys to existing keys
          keys = [].concat(keys, getSubKeys(assembledKey, subsubkey, subEntries))
        })

      }

      return keys
    }

    const firstLevelKeys = Object.keys(jsonObject)
    let keys: string[] = []
    // add firstLevelKeys and subkeys to the keys
    firstLevelKeys.forEach(key => {
      keys = [].concat(keys, getSubKeys("", key, jsonObject))
    })

    return keys
  }


  /**
   * provides an array of all keys of a translation file as array like
   * [
   * "page",
   * "page.about",
   * "page.about.title",
   * "page.contact",
   * "page.contact.title"
   * ...
   * ]
   *
   * @param file
   * @returns
   */
  public static getAllAssembledKeysFromJsonFile(file: string): string[] {
    let entries: JsonEntriesType = {}
    // try/catch to avoid error for testing on not existing files
    try {
      // NOTE: this require-statement provoces a warning
      // "Critical dependency: the request of a dependency is an expression"
      // because the loaded file is dynamically defined
      // @todo solution for loading a json file dynamically
      /* eslint-disable @typescript-eslint/no-var-requires */
      entries = require(file)
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error on importing ", file, "in getAllKeysFromJsonFile: \n", err)
    }

    return JSONHelper.getAllAssembledKeysFromJsonObject(entries)
  }
}