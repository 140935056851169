import Head from "next/head"
import { ReactNode } from "react"
import { connect, ConnectedProps } from "react-redux"
import { AnyAction, Dispatch } from "redux"

import Footer from "@components/layout/Footer"
import Header from "@components/layout/Header"
import PageBody from "@components/layout/PageBody"
import { AppState } from "@redux/reducer"
import { selectRoles, selectUsername } from "@redux/reducer/auth"
import { logoutAction } from "@redux/usecases/userAccount"

import cssVariables from "./../../styles/constants.module.scss"
import FAQFlyout from "./common/faq/FAQFlyout"


/**
 * This component provides the basis layout of the client-application, including
 * - html-header
 * - page-header
 * - body with component children
 * - footer
 *
 * BaseLayout is used by every page at /pages to let it look the same way
 */

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  doLogout: () => dispatch(logoutAction()),
})

const mapStateToProps = (state: AppState) => ({
  roles: selectRoles(state),
  username: selectUsername(state),
  authTTL: state.platform.authTTL,
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type Props = ConnectedProps<typeof connector> & {
  children: ReactNode
  isFrontPage?: boolean
  isMarketplace?: boolean
  pageTitle: string
  metaData?: ReactNode
}

/**
 * This component provides a basic layout for every page
 * and should therefor be used in every page.
 */
const BaseLayout: React.FC<Props> = (props: Props) => {
  return <>
    <Head>
      <title>{props.pageTitle}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="robots" content="index, follow" />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="48x48" href="/favicon.png" />
      <link rel="manifest" href="/manifest.json" />
      <meta name="msapplication-TileColor" content={cssVariables.white} />
      <meta name="msapplication-TileImage" content="/mstyle-144x144.png" />
      <meta name="theme-color" content={cssVariables.white} />
      {/* Data Protection, @see: https://webbkoll.dataskydd.net/en/results?url=http://dev.projektfabrik.net/ */}
      <meta name="referrer" content="no-referrer" />
      {/* the following definition does not work since it does not allow to load content from other sources than itself.
      @todo: how to configure all (!) allowed content-elements, including embedded videos + video conferencing?
      @see: https://www.stackhawk.com/blog/react-content-security-policy-guide-what-it-is-and-how-to-enable-it/
      <meta httpEquiv="Content-Security-Policy" content="script-src 'self'" />
      */}

      {/* social media card default definitions */}
      <meta name="og:title" content={props.pageTitle} />
      <meta name="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta name="twitter:title" content={props.pageTitle} />
      {/* additional meta data, especially for social media card details */}
      {props.metaData}
    </Head>

    <div className="container-with-content-and-flyout">
      {
        // the id is important for the infinite scrollcomponent to identify, which is the scroll target
      }
      <div id="scrollable-page" className={"common-page " + (props.isFrontPage ? "frontpage" : "subpage")}>
        <Header
          authTTL={props.authTTL}
          doLogout={props.doLogout}
          isFrontPage={props.isFrontPage}
          isMarketplace={props.isMarketplace}
          pageTitle={props.pageTitle}
          roles={props.roles}
          username={props.username}
        />
        <PageBody>
          {props.children}
        </PageBody>
        <Footer
          isFrontPage={props.isFrontPage}
        />
      </div>
      {
        /**
         * @todo phillipp: replace with Flyout and find a way to insert the FAQMainComponent
         * Why?
         * The idea is to insert any content into the flyout. Therefor the trigger has to know, which content should be displayed on the flyout.
         * Current general design: Any possible content should be a child of the flyout component.
         *
         * Currently I discovered two possibilities:
         *
         * 1) Identify the children of the Flyout through theirs names. Each child has within the type their name. Is it always available?
         *
         * Example Code:
         *
          const findComponentToDisplayInFlyout = (): JSX.Element => {
            let result: JSX.Element = null
              Children.forEach(children, child => {
                 if (nameOfComponent === child.type.name) {
                   result = child
                   return
                  }
              })
            return result
          }
         *
         * 2) Define a child component, which has name property for identification und a component property, which represents the content.
         * With PropTyps you can make sure, that these properties are requiered of each children within the flyout.
         *
         * Example Code for PropTyp
         *
          Flyout.propTypes = {
            children: PropTypes.shape({
              props: PropTypes.shape({
                  name: PropTypes.string.isRequired,
                  component: PropTypes.element.isRequired
              })
            }).isRequired,
          }
         * Example Code for the additional child component:
         *
          type ChildProps = {
            name: string
            component: JSX.Element
          }
          export const FlyoutChild = ({ name, component }: ChildProps): JSX.Element => {
              const contentURI = useSelector(selectFlyoutContentURI)
              const parts = contentURI.split("://")
              const nameOfComponent = parts[0]
              if (nameOfComponent === name) {
                  return component
              }
              return null
          }
         */
      }
      <FAQFlyout />
    </div>
  </>
}

export default connector(BaseLayout)


