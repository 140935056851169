import withTranslation from 'next-translate/withTranslation'
import React, { ReactNode } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { InputType } from "reactstrap/lib/Input"

import FAQPopup from "@components/common/faq/FAQPopup"
import TranslatedHtml from "@components/common/TranslatedHtml"

import FormikElement, { IBaseFormikProps } from "./FormikElement"

/**
 * this component provides a Formik-based Input-Form-Element
 */

interface IProps extends IBaseFormikProps {
  children: ReactNode
  className: string
  /**
   * set true, if the visible character-count should count line breaks:
   * if line breaks are allowed, they need to be counted to avoid overrun the character limit.
   * If they are filtered out before sending the content, they should not be counted.
   * */
  countLineBreaks?: boolean
  i18n: any
  name: string
  noGroup?: boolean
  placeholder?: string
  type?: InputType
  value?: any
  onFocus?: () => void
  disabled?: boolean
  maxLength?: number
}

class FormikInput extends FormikElement<IProps> {

  lengthContainer: React.RefObject<HTMLDivElement> = null
  spanShowLength: React.RefObject<HTMLSpanElement> = null

  constructor(props: IProps) {
    super(props)
    // create a ref to store the textInput DOM element
    this.lengthContainer = React.createRef()
    this.spanShowLength = React.createRef()
  }

  calculateTextLength = (text: string): number => {
    return text
      ? this.props.countLineBreaks
        ? text.length
        : text.replace(/(?:\r|\n)/g, '').length
      : 0
  }

  // show the current text length in the editor, called within render
  // is only updated, if the type is a textarea
  updateTextLength = () => {
    if (this.props.type === "textarea") {
      // To be consistent with the field validation: don't count linebreaks,
      // they will be removed completely by stripHtml().
      const length = this.calculateTextLength(this.props.field.value as string)

      if (this.spanShowLength.current) {
        this.spanShowLength.current.innerHTML = length.toString()
      }

      if (this.props.maxLength) {
        if (this.lengthContainer.current) {
          this.lengthContainer.current.classList
            .toggle("text-danger", length > this.props.maxLength)
        }
      }
    }
  }

  public render = (): ReactNode => {
    // extract all properties not allowed / not needed for the input element so the don't get
    // passed down and appear in the HTML
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, field, form, help, i18n, id, label, meta, noGroup = false,
      placeholder, type, value, onFocus, disabled, maxLength, role, ...props } = this.props
    const labelText = this.labelText()

    const element = <>
      <div>
        {labelText.length > 0 &&
          <Label for={field.name}>{labelText}
            {this.props.faqpopup && /* set an faq-popup, if given */
              <FAQPopup {...this.props.faqpopup} />
            }
          </Label>
        }
        {this.helpElement()}
      </div>
      <Input
        {...field}
        {...props}
        id={id || field.name}
        name={field.name}
        title={field.name}
        invalid={this.hasError()}
        placeholder={placeholder ? i18n.t(placeholder) : null}
        type={type || "text"}
        value={value === null ? "" : value}
        onFocus={onFocus}
        disabled={disabled}
        role={role || "input"}
      >{children}</Input>
      {
        type === "textarea" && <div className="textlength" ref={this.lengthContainer}>
          <span ref={this.spanShowLength}>{this.calculateTextLength(value as string)}</span> {
            maxLength
              ? <TranslatedHtml content="form.RTE.textLengthWithMaxLength" params={{ maxLength }} />
              : <TranslatedHtml content="form.RTE.textLength" />
          }</div>
      }
      {this.errorElement()}
    </>
    this.updateTextLength()
    return noGroup ? element : <FormGroup>{element}</FormGroup>
  }
}

export default withTranslation(FormikInput, "common")
