import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip } from "reactstrap"

import { UserRole } from "@api/schema"
import AuthElement from "@components/common/AuthElement"
import DropdownComponent from "@components/common/DropdownComponent"
import FAQFlyoutTriggerIcon from "@components/common/faq/FAQFlyoutTriggerIcon"
import Icon from "@components/common/Icon"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { newLoadCollectionAction } from "@redux/helper/actions"
import { AppState } from "@redux/reducer"
import { selectCurrentUserId } from "@redux/reducer/auth"
import { selectCollectionUsecaseState } from "@redux/reducer/data"
import { IDiscussionFilterCriteria } from "@redux/reducer/feedback"
import { EntityType } from "@redux/reduxTypes"
import { FeedbackHelper } from "@services/feedbackHelper"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { Routes } from "@services/routes"
import { humanTime } from "@services/util"
import { MODULE_FEEDBACK_FOR_PM_AVAILABLE, MODULE_FEEDBACK_FOR_PROJECTS_AVAILABLE, MODULE_LOGOUT_TIMER_AVAILABLE } from "config"

import NavIcon from "./NavIcon"
import NavigationDropDownItem from "./NavigationDropDownItem"

const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  // @todo diese ganzen Properties nicht aus den Elternkomponenten holen sondern
  // aus mapStateToProps damit die Elternkomponenten nicht neu gerendert werden weil sich
  // props ändern die sie nicht brauchen sondern nur durchreichen: https://i.imgur.com/xpwcEH3.png
  authTTL: number
  doLogout: () => void
  isFrontPage?: boolean
  roles: string[]
  username: string
}

/**
 * provides the navigation icons on every page of the application
 */
const HeaderIconNavigation: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()
  const dispatch = useDispatch()


  // #region does the user has discussions to show the discussion navigation entry?

  // @todo instead of fetching the discussions here it may be an option to create and endpoint
  // to fetch user statistics with data about his discussions
  const currentUserId = useSelector(selectCurrentUserId)
  const discussionUseCaseKey = FeedbackHelper.usecaseKeyForUsersDiscussions(currentUserId?.toString())

  /** state of the discussion request */
  const userDiscussionState = useSelector((state: AppState) =>
    selectCollectionUsecaseState(
      state,
      EntityType.Discussion,
      discussionUseCaseKey
    )
  )

  useEffect(() => {
    if (currentUserId && !userDiscussionState?.loaded) {
      // load discussion(s) for the current the current user
      dispatch(newLoadCollectionAction(
        EntityType.Discussion,
        {
          createdBy: currentUserId.toString(),
        } as IDiscussionFilterCriteria,
        discussionUseCaseKey
      ))
    }
  }, [currentUserId])

  // #endregion

  const [logoutTimerTooltipOpen, setLogoutTimerTooltipOpen] = useState(false)
  const toggleLogoutTimerTooltip = () => {
    setLogoutTimerTooltipOpen(!logoutTimerTooltipOpen)
    //    setTimeout(() => setTooltipOpen(false), tooltipTimeout)
  }

  return <nav role="navigation" className="icon-navigation">
    <AuthElement roles={props.roles} requiredRole={[UserRole.Admin, UserRole.ProcessManager]}>
      <NavIcon href={Routes.AdminDashboard} icon="monitor" title={t("base-layout", "adminDashboard")} />
    </AuthElement>

    <NavIcon href={Routes.Marketplace} icon="market" title={t("base-layout", "marketplace")} />
    <NavIcon href={Routes.ProcessOverview} icon="info-program" title={t("base-layout", "processOverview")} />



    <DropdownComponent
      aria-label={t("base-layout", "user")}
      autoWrap={false}
      button={
        <span className="actionicon"><Icon name="user" size={24} /></span>
      }
      light={props.isFrontPage}
      title={t("base-layout", "user")}
    >
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "userProfile")}
        href={Routes.UserProfile}
      />

      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "myProjects")}
        href={Routes.MyProjects}
      />

      {// show discussion navigation item only, if one feedback module is available
        // and if the user has at least one discussion
        (MODULE_FEEDBACK_FOR_PROJECTS_AVAILABLE || MODULE_FEEDBACK_FOR_PM_AVAILABLE) &&
        userDiscussionState?.loaded && userDiscussionState?.totalItems > 0 &&
        <NavigationDropDownItem
          roles={props.roles}
          requiredRole={UserRole.User}
          label={t("base-layout", "myDiscussions")}
          href={Routes.Feedback}
        />
      }
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.User}
        label={t("base-layout", "logout")}
        onClick={props.doLogout}
      />
      <NavigationDropDownItem
        roles={props.roles}
        requiredRole={UserRole.Guest}
        href={Routes.Login}
        label={t("base-layout", "login")}
      />
    </DropdownComponent>

    {MODULE_LOGOUT_TIMER_AVAILABLE && props.authTTL > 0 && <AuthElement roles={props.roles} requiredRole={UserRole.User}>
      <div id="logoutTimer">{humanTime(props.authTTL)}</div>
      <Tooltip target={"logoutTimer"} toggle={toggleLogoutTimerTooltip} isOpen={logoutTimerTooltipOpen}>
        {t("base-layout", "timeTillLogout")}
      </Tooltip>

    </AuthElement>
    }

    <FAQFlyoutTriggerIcon className="actionicon faq-icon" />
  </nav >
}

export default withDynamicNamespaces<IProps>(HeaderIconNavigation, usedNamespaces)