import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
interface IProps {
  children?: React.ReactNode
  href?: string
  activeClassName?: string
}

// NOTE this file is not used anywhere
// it could be used for breadcrum navigation

/**
 * Provides a link, that has a className to highlight it as "active"
 * when the given href is similar to the current Route of the user.
 *
 * That allows an "automatic" highlighting when the user surfs the platform, e.g. for navigation icons.
 *
 * @todo rename to ActiveNavigationLink to be more clear where to use
 */
const ActiveLink: React.FC<IProps> = ({ children, href, ...props }: IProps) => {
  const child = React.Children.only(children) as React.ReactElement<any>
  const router = useRouter()

  const activeClassName = props.activeClassName ? props.activeClassName : "active"

  let className: string = child.props.className || ""

  // when the complete URL matches, we don't want links to be shown as active if only a subpage is active,
  // e.g. /projects should not be active when /projects/own is displayed
  if (router.pathname === href) {
    className = `${className} ${activeClassName}`.trim()
  }

  delete props.activeClassName

  return <Link href={href} passHref {...props}>{React.cloneElement(child, { className })}</Link>
}

export default ActiveLink
