import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { setFlyoutContentURIAction, toggleFlyoutVisibilityAction } from "@redux/actions/application"
import { isFlyoutVisible } from "@redux/reducer/applicationStates"
import { NamespaceShortcut, prefixedKey, useDynamicTranslation } from "@services/i18n"
import { MODULE_FAQ_AVAILABLE } from "config"

import ActionIcon from "../ActionIcon"
import TranslatedHtml from '../TranslatedHtml'
import { FAQKeys } from "./FAQHelper"


const usedNamespaces: NamespaceShortcut[] = ["default", "faq", "faq-meta"]

/**
 * exportable interface, to integrate the faqpopup more easy into formik-elements or other components
 */
export interface IFAQPopupProps {
  faqKey: string
  size?: number
  className?: string
  arialabel?: string
}

/**
 * This component provides a standard-usage of an PopUp for additional information. It is part of the FAQ-System.
 *
 * The component does not reuse the FAQEntryLayer-Component: it simply chooses an entry and displays a link
 * for more detailed information to the FAQComponent in the Flyout.
 *
 * parameters:
 * faqKey: a string. It is a key, which is a reference to a question and an answer. Include the translation-file, e.g.: common:doc.projects.team. Use HTML to structure the text!
 * size: size of the icon, default: 24
 * className: additional style-class(es)
 * arialabel:
 *
 * The translation are stored in "locales/de/doc.json" and "locales/en/doc.json".
 * Codeexample's for creating an FAQPopup, e.g.:
 * <FAQPopup key="faq:introduction.projects.dashboard" />
 *
 * Recommendation for usage of FAQPopup:
 * => Definition of one or multiple terms/concepts
 * => Any Definition or explanation should be short and simple
 * => Ask yourself:
 * 1) "Do I have terms to explain?" -> Yes? Go to second question:
 * 2) "If you delete the Popup, would you still get the necessary information from the context of the page" -> No? Use the Popup!
 *
 * Don't use FAQPopup for explanation on how to use a function or the platform. It's not an introduction or tutorial tool.
 * Don't use it, if it's not more than 4 words (use Tooltip instead!).
 *
 * Hint for using Images, Video's or other Mediatypes: As a redacteur use HTML in the translation.json.
 *
 * @todo Default size of FAQPopUp, add scrollbar?
 */
const FAQPopup: React.FC<IFAQPopupProps> = (props: IFAQPopupProps) => {
  const {
    faqKey,
    className,
    arialabel
  } = props

  const dispatch = useDispatch()
  const flyoutIsVisible = useSelector(isFlyoutVisible)

  const t = useDynamicTranslation()

  // stores if a popup is visible or not
  const [popupOpen, setPopupOpen] = useState(false)

  return (MODULE_FAQ_AVAILABLE && faqKey) && <>
    <ActionIcon
      aria-label={arialabel || t("faq-meta", "flyout.ariaLabel")}
      onclick={() => setPopupOpen(!popupOpen)}
      className={className ? className : "actionicon"}
      title={t("faq-meta", "flyout." + (flyoutIsVisible ? "close" : "open"))}
      icon="question-mark-circle"
    />

    <Modal
      isOpen={popupOpen}
      toggle={() => setPopupOpen(false)}
    >
      <ModalHeader>
        <TranslatedHtml content={prefixedKey("faq", faqKey + "." + FAQKeys.Question)} />
      </ModalHeader>

      <hr className="popup__hr" />

      <ModalBody className="faq-popup__body">
        <TranslatedHtml content={prefixedKey("faq", faqKey + "." + FAQKeys.Answer)} />
      </ModalBody>

      <ModalFooter className="faq-popup__footer">
        <TranslatedHtml
          content={prefixedKey("faq-meta", "additionInformation")}
          params={{
            topic: t("faq", faqKey.substring(0, faqKey.lastIndexOf(".")) + "." + FAQKeys.Title)
          }}
        />
        {
          // if the FAQFlyoutTrigger is clicked, the modal will be closed und Flyout will open with the given faqKey
          // It will be shown the topic with all other questions
        }
        <ActionIcon
          aria-label={t("faq-meta", "flyout.ariaLabel")}
          title={t("faq-meta", "flyout." + (flyoutIsVisible ? "close" : "open"))}
          icon="question-mark-circle"
          onclick={() => {
            // @todo: clarify: could it be, that no faqKey is set?
            if (faqKey) {
              // close popup
              setPopupOpen(false)
              // open flyout with FAQ
              dispatch(
                setFlyoutContentURIAction(
                  // remove a potential namespace prefix, shorten the faqKey to area.topic-layer with opened up faq entries
                  // to see more entries of the same topic
                  // @todo: clarify: could it be that faq: is given as prefix? if not, this cut-out operation is not necessary
                  faqKey.includes("faq:")
                    ? faqKey.substring(0, faqKey.lastIndexOf(".")).replace("faq:", "")
                    : faqKey.substring(0, faqKey.lastIndexOf("."))
                )
              )
              if (!flyoutIsVisible) {
                // change flyout visibility only, if it is not visible yet
                // otherwise an already open flyout would close if the user clicks on this ActionIcon
                dispatch(toggleFlyoutVisibilityAction())
              }
            }
          }}
        />
      </ModalFooter>
    </Modal>
  </>
}

export default withDynamicNamespaces<IFAQPopupProps>(FAQPopup, usedNamespaces)