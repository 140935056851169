import Link from "next/link"
import { Col, Row } from "reactstrap"

import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"
import { hashIdContactPageBugReport } from "@services/processHelper"
import { Routes } from "@services/routes"

import LanguageSelector from "./Footer/LanguageSelector"
import SocialLinks from "./SocialLinks"



const usedNamespaces: NamespaceShortcut[] = ["base-layout"]

interface IProps {
  isFrontPage?: boolean
}

/**
 * This component provides the page-footer of the client-application, used by BaseLayout
 */
const Footer: React.FC<IProps> = (props: IProps) => {
  const t = useDynamicTranslation()

  return (
    <footer>
      <Row className="footer-content">
        <Col className="footer__side-cols">
          {props.isFrontPage && <SocialLinks />}
        </Col>
        <Col className="footer-links">
          <Link href={Routes.About}>
            <a title={t("base-layout", "aboutTitle")}>{t("base-layout", "about")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.Contact}>
            <a>{t("base-layout", "contact")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.Imprint}>
            <a>{t("base-layout", "imprint")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.DataProtection}>
            <a>{t("base-layout", "dataProtection")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.Netiquette}>
            <a>{t("base-layout", "netiquette")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.Registration}>
            <a>{t("base-layout", "register")}</a>
          </Link>{" "}
          |{" "}
          <Link href={Routes.Contact + hashIdContactPageBugReport}>
            <a>{t("base-layout", "bugReport")}</a>
          </Link>
        </Col>
        <Col className="footer__side-cols">
          <LanguageSelector />
        </Col>
      </Row>
    </footer>
  )
}

export default withDynamicNamespaces<IProps>(Footer, usedNamespaces)
