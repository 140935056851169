import { ReactNode } from "react"

type Props = {
  children: ReactNode
}

const PageBody: React.FC<Props> = ({ children }: Props) => <main className="main-container" role="main">
  {children}
</main>

export default PageBody