import { useEffect, useRef, useState } from "react"
import { Tooltip } from "reactstrap"

import ActiveLink from "@components/common/ActiveLink"
import Icon, { IconName } from "@components/common/Icon"

interface IProps {
  href: string
  icon: IconName
  /**
   * A (translated) text ready for display.
   */
  title: string
}

/**
 * Provides an icon for navigation as ActiveLink with a tooltip.
 * Note that `title` is a string that is already translated, and not an i18n key.
 */
const NavIcon: React.FC<IProps> = ({ href, icon, title }: IProps) => {

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen)
  }


  // Hint of the official documentation: https://reactjs.org/docs/hooks-reference.html#useref
  /**
   * Keep in mind that useRef doesn’t notify you when its content changes.
   * Mutating the .current property doesn’t cause a re-render.
   * If you want to run some code when React attaches or detaches a ref to a DOM node,
   * you may want to use a callback ref instead.
   */
  // e.g the tooltip should be display when the targets are initialized, that mean's if the target reference is initialized
  // if the tooltipTargetRef is still null, the tooltip will not be rendered
  // Be aware: the tooltipTargetRef can be intialized before the first render, but there is no guarante
  //
  // solution: adding a useState und a useEffect, which has the tooltipTargetRef in the dependency array
  // the callback idea is not suitable in this case with making sure to display the tooltip, cause with the callback, there is e.g. the possiblity to do e.g. manipulation on the target or focus,
  // but to display the tooltip, a rerender is needed, therefor a useState and a useEffect is the suitable solution
  const [targetRefInitialized, setTargetRefInitialized] = useState(false)
  // reference to to the tooltip target, initially null => needed to make sure, that the tooltip is rendered after the tooltip targets are mounted
  const tooltipTargetRef = useRef(null)

  useEffect(() => {
    if (tooltipTargetRef?.current) {
      setTargetRefInitialized(true)
    }
  }, [tooltipTargetRef])


  return <>
    <ActiveLink href={href}>
      <a
        aria-label={title}
        title={title}
        className="actionicon"
        id={icon}
        ref={tooltipTargetRef}
      ><Icon name={icon} size={24} />
      </a>
    </ActiveLink>
    {title !== "" && targetRefInitialized && (
      <Tooltip
        target={tooltipTargetRef.current}
        toggle={toggleTooltip}
        isOpen={tooltipOpen}
        placement="top"
      >
        {title}
      </Tooltip>)
    }
  </>
}

export default NavIcon
