import { useRouter } from "next/router"
import { DropdownItem } from "reactstrap"

import { UserRole } from "@api/schema"
import ActiveLink from "@components/common/ActiveLink"
import AuthElement from "@components/common/AuthElement"

interface IProps {
  href?: string
  /**
   * A (translated) text ready for display.
   */
  label: string
  requiredRole: UserRole | UserRole[]
  roles: string[]
  onClick?: (...args: any) => void
}

/**
 * Provides an DropdownItem within an AuthElement if needed.
 * Either href or onClick must be provided
 * Note that `label` is a string that is already translated, and not an i18n key.
 */
const NavigationDropDownItem: React.FC<IProps> = ({ href, label = "", onClick, requiredRole, roles }: IProps) => {
  const router = useRouter()

  let navigationLink = <a title={label} onClick={onClick}>{label}</a>

  navigationLink = href
    ? <ActiveLink href={href}>
      {navigationLink}
    </ActiveLink>
    : navigationLink


  const dropdownItem = <DropdownItem onKeyDown={(event) => {
    if (event.key === "Enter") {
      if (href) {
        void router.push(href)
      } else {
        if (onClick) {
          onClick()
        }
      }
    }
  }}>
    {navigationLink}
  </DropdownItem>

  // wrap the dropdownItem into an AuthElement, if requiredRole is given
  return requiredRole
    ? <AuthElement roles={roles} requiredRole={requiredRole}>
      {dropdownItem}
    </AuthElement>
    : dropdownItem
}

export default NavigationDropDownItem
