import { FunctionComponent, ReactNode } from "react"

import { UserRole } from "@api/schema"
import { hasMatches } from "@services/util"

interface IProps {
  children: ReactNode
  requiredRole: UserRole | UserRole[]
  roles: string[]
}

const AuthElement: FunctionComponent<IProps> = ({ children, requiredRole, roles }: IProps) => {
  if (hasMatches(roles, requiredRole)) {
    return <>{children}</>
  }

  if (requiredRole === UserRole.Guest && !roles.includes(UserRole.User)) {
    return <>{children}</>
  }

  return null
}

export default AuthElement
