import { Col, Row } from "reactstrap"

import HeaderIconNavigation from "@components/layout/Header/HeaderIconNavigation"
import HeaderLogo from "@components/layout/Header/HeaderLogo"

interface IProps {
  authTTL: number
  doLogout: () => void
  isFrontPage?: boolean
  isMarketplace?: boolean
  pageTitle: string
  roles: string[]
  username: string
}

/**
 * provides the common header of the layout for every page, used by BaseLayout
 */
const Header: React.FC<IProps> = (props: IProps) => (
  <header>
    <Row>
      <Col><HeaderLogo isMarketplace={props.isMarketplace} /></Col>
      <Col className="col-lg-6">
        {props.isFrontPage ?
          // possibility to place a header-image in a third col between logo and nav, used only for custom client design, link to image to be overwritten in custom-styles
          <div className="custom-frontpage-header-div">
          </div>
          : <div role="banner" className="page-title">
            {props.pageTitle}
          </div>
        }

      </Col>
      <Col>
        <HeaderIconNavigation
          authTTL={props.authTTL}
          doLogout={props.doLogout}
          isFrontPage={props.isFrontPage}
          roles={props.roles}
          username={props.username}
        />
      </Col>
    </Row>
  </header>
)

export default Header